import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ProductBar from './Productbar';
import ProductSpace from './Productspace';

const App = () => (
	<BrowserRouter>
		<div id="outer-container">
			<ProductBar />
			<ProductSpace />
		</div>
	</BrowserRouter>
);

export default App;
