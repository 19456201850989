import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: PassedComponent, user, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (user !== null && user.hasAccess) {
				return (
					<Suspense fallback={<div>Loading...</div>}>
						<PassedComponent user={user} {...props} />
					</Suspense>
				);
			}
			return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
		}}
	/>
);

export default PrivateRoute;
