import { combineReducers } from 'redux-immutable'
import reducers from './reducers'

// If we need access to the full state tree use thunk

const appReducer = combineReducers(reducers)

const rootReducer = (state, action) => {
  // We remove persisted state so we need to ensure a state exists for the following:
  let stateCopy = state
  if (state) {
    if (action.type === 'LOGOUT') {
      stateCopy = undefined
    } 
  }
  return appReducer(stateCopy, action)
}


export default rootReducer
