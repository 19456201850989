import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProductSpaceRoutes from '../../routes/ProductspaceRoutes';

const Productspace = ({ user }) => (
	<div className="product-space">
		<ProductSpaceRoutes user={user} />
	</div>
);

const mapStateToProps = (state) => ({
	user: state.get('user') ? state.get('user').toJS() : null
});

export default withRouter(connect(mapStateToProps)(Productspace));
